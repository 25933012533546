<template>
  <div class="row justify-start items-center">
    <q-icon :name="icon" class="icon-title-group" />
    <h3 class="title-info">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "TitleGroup",
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.title-info {
  margin-left: 0.5rem;
  font-size: 14pt;
  font-weight: 600;
}
.icon-title-group {
  font-size: 20pt;
}
.mobile {
  .title-info {
    font-size: 10pt;
  }
}
</style>
