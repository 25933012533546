<template>
  <q-page>
    <page-title
      class="q-mb-lg"
      title-class
      :skeleton="false"
      :title="$t('my_points.faq.page_title')"
    />
    <div
      class="
        points-info-container
        column
        justify-around
        q-mb-lg q-pa-md
        bg-white
      "
    >
      <title-group
        class="q-ml-sm text-uppercase"
        icon="new_releases"
        :title="$t('my_points.faq.title_1')"
      ></title-group>
      <p class="container-limited-60 text-justify text-align-left">
        {{ $t("my_points.faq.text_1") }}
        <strong>{{ $t("my_points.faq.value_converted") }}</strong>
      </p>
      <title-group
        class="desktop-container q-ml-sm text-uppercase"
        icon="trending_up"
        :title="$t('my_points.faq.title_2')"
      ></title-group>
      <div class="row q-mb-md">
        <div class="col-12 col-lg-6 q-mx-auto text-align-dual">
          <p>{{ $t("my_points.faq.text_2") }}</p>
          <div class="points-info-field ">
            {{
              $t("my_points.faq.text_label_1", {
                value: pri,
              })
            }}
          </div>
        </div>

        <div class="col-12 col-lg-6 q-mx-auto text-align-dual padding-content">
          <p v-if="sec > 0">
            {{ $t("my_points.faq.text_3") }}
          </p>
          <div class="points-info-field" v-if="sec > 0">
            {{ $t("my_points.faq.text_label_2", { value: sec }) }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="points-info-container q-pb-md q-mb-lg bg-white q-pa-md"
      v-if="clusters.length > 0"
    >
      <title-group
        class="desktop-container text-uppercase container-limited-60 q-ml-sm"
        icon="beenhere"
        :title="$t('my_points.faq.title_3')"
      ></title-group>

      <p class="container-limited-60 text-justify text-align-left">
        {{ $t("my_points.faq.text_4") }}
      </p>

      <div class="row justify-around q-mb-lg" v-if="partner">
        <card-points
          v-for="item in clusters"
          :key="item.id"
          :level="item.description"
          :backgroundColor="item.color"
          :color="item.color_front"
          :percent="`${parseInt(item.percent_default)}%`"
          :initial="item.init_billing_range"
          :final="item.end_billing_range"
          :clusterId="item.id"
          :partner="partner"
          :clusterPartnerId="partner.cluster_id"
          :carousel_images="item.carousel_images"
        />
      </div>
      <p class="container-limited-60 text-justify q-mt-lg text-align-left">
        {{ $t("my_points.faq.text_5") }}
      </p>
    </div>
    <div
      class="points-info-container q-pb-md q-mb-lg bg-white q-pa-md"
      v-if="Object.keys(getSplitClustersRules).length"
    >
      <title-group
        class="desktop-container text-uppercase container-limited-60 q-ml-sm"
        icon="gavel"
        :title="$t('my_points.faq.title_5')"
      ></title-group>

      <div class="q-mr-md q-ml-md">
        <div v-for="(rule, i) in getSplitClustersRules" :key="i">
          <div class="text-h6 text-uppercase text-grey-10 text-weight-bolder">
            {{ rule.cluster }}
          </div>
          <p class="text-justify" style="font-size: 11pt;">
            {{ rule.infos }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="points-info-container q-pb-md q-mb-lg bg-white q-pa-md"
      v-if="getFaqQuestions.length > 0"
    >
      <title-group
        class="desktop-container text-uppercase container-limited-60 q-ml-sm"
        icon="calendar"
        :title="$t('my_points.faq.title_4')"
      ></title-group>
      <p class="container-limited-60 text-justify text-align-left">
        {{ $t("my_points.faq.text_6") }}
      </p>
      <div
        class="
          desktop-container
          container-limited-80
          row
          justify-around
          q-mb-lg
        "
      >
        <card-reward
          v-for="faq in getFaqQuestions"
          :key="faq.id"
          :title="faq.trigger_select.description"
          :subTitle="faq.trigger_select.helper"
          :label="faq.trigger_select.tip === null ? '' : faq.trigger_select.tip"
        />
      </div>
    </div>
  </q-page>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import TitleGroup from "./components/TitleGroup.vue";
import PageTitle from "../../components/page-title";
import CardPoints from "./components/CardPoints";
import CardReward from "./components/CardReward";
import { ref, onMounted } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("my_points");

export default {
  name: "faq",

  components: {
    CardPoints,
    TitleGroup,
    CardReward,
    PageTitle,
  },

  setup() {
    const pri = ref(""),
      sec = ref(""),
      clusters = ref([]),
      splitException = ref(""),
      partner = ref([]),
      {
        getCommission,
        getSplitExceptions,
        getPartner,
        getCluster,
        getFaqQuestions,
        getSplitClustersRules,
      } = useGetters([
        "getCommission",
        "getSplitExceptions",
        "getPartner",
        "getCluster",
        "getFaqQuestions",
        "getSplitClustersRules",
      ]),
      {
        fetchCommission,
        fetchSplitExceptions,
        fetchPartner,
        fetchSplitClusters,
        fetchFaqQuestions,
        fetchSplitClustersRules,
      } = useActions([
        "fetchCommission",
        "fetchSplitExceptions",
        "fetchPartner",
        "fetchSplitClusters",
        "fetchFaqQuestions",
        "fetchSplitClustersRules",
      ]),
      getCurrentCluster = (cluster_id) => {
        if (clusters.value.length > 0 && cluster_id != null) {
          clusters.value.forEach((el) => {
            if (el.id == cluster_id) {
              pri.value = parseInt(el.percent_default);
            }
          });
        }
      };

    // TODO: Essa forma de trabalhar com onMounted não é recomendável
    onMounted(async () => {
      fetchSplitClustersRules({ keys: ["partner_cluster_rules"] });
      fetchCommission().then(() => {
        pri.value = parseInt(getCommission.value[0].fixed_percent);
        sec.value = parseInt(getCommission.value[1].fixed_percent);
      });
      fetchSplitClusters().then(() => {
        clusters.value = getCluster.value;
      });
      fetchFaqQuestions();
      await fetchPartner();
      partner.value = getPartner.value;
      getCurrentCluster(partner.value.cluster_id);
      fetchSplitExceptions().then(() => {
        splitException.value = getSplitExceptions.value;
      });
    });

    return {
      pri,
      sec,
      partner,
      clusters,
      getCommission,
      splitException,
      getFaqQuestions,
      getSplitClustersRules,
      getCurrentCluster,
    };
  },
};
</script>

<style lang="scss">
body {
  background-color: #f7f7f7 !important;
}
p {
  font-size: 12pt;
}
.text-align-left {
  padding-left: 15px;
}
.card-border-bottom {
  width: 120%;
  height: 4px;
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #00000033;
}
.text-align-dual {
  text-align: center;
}
.page-title {
  font-size: 1.8rem;
  color: #5c5c5c;
}
.title-container {
  width: 70%;
  font-size: 1.6rem;
  margin: 0 auto;
  display: block;
}
.text-grey-info-card {
  color: #707070;
  font-size: 1.4rem;
  font-weight: 500;
}

body #app {
  overflow-x: hidden;
}

.points-info-container {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  border-radius: 15px;
}

.title-info:nth-child(1) {
  font-size: 1.2rem;
}

.sub-title-info {
  padding: 1rem 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000cc;
  display: flex;
  text-align: justify;
}

.points-info-field {
  margin: auto;
  width: 65%;
  text-align: center;
  background-color: #666564;
  border-radius: 15px;
  box-shadow: none;
  color: white;
  font-size: 12pt;
  text-align: center;
  padding: 10px;
  &::placeholder {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    color: #00000077;
  }
  &:disabled {
    background-color: #ff770e;
  }
}
.span-text {
  font-size: 0.95rem;
  display: block;
}

@media (max-width: 1440px) {
  .padding-content {
    padding-top: 1em;
  }
  .container-limited-60 {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .points-info-field {
    width: 100%;
  }
  .text-align-left {
    padding-left: 0px;
  }
  .sub-title-info {
    font-size: 1.5rem !important;
  }
  .info-text-card-point {
    width: 87%;
    margin: 1.5rem auto;
  }
  .text-align-dual {
    text-align: justify;
  }
}

@media (min-width: 1200px) {
  .points-info-container {
    padding-bottom: 2rem;
  }
  .break-line {
    display: none;
  }
  .sub-title-info {
    font-size: 1.4rem !important;
  }
  .container-limited-80 {
    width: 90%;
  }
}
</style>
