<template>
  <div
    class="
      card-reward
      col-12 col-md-6 col-lg-4
      column
      justify-around
      items-center
    "
  >
    <span class="card-reward-title text-justify">{{ title }}</span>
    <img src="../assets/icons/sinalization.svg" alt="" />
    <template v-if="subTitle != null">
      <span class="info-text card-reward-sub-title">
        {{ isCompacted === true ? subTitle.substr(0, 99) + "..." : subTitle }}
      </span>

      <span
        v-if="isCompacted"
        @click="isCompacted = !isCompacted"
        class="show-more-btn"
        >mostrar mais</span
      >
      <span v-else @click="isCompacted = !isCompacted" class="show-more-btn"
        >mostrar menos</span
      >
      <span class="info-text info-text-bottom card-reward-label">
        {{ label.length > 0 ? label.substr(0, 20) : "" }}
      </span>
    </template>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "card-reward",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },

  setup() {
    const isCompacted = ref(true);

    return {
      isCompacted,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-reward {
  width: 100%;
  background-color: white;
  margin: 2rem auto 0;
  box-shadow: 1px 3px 3px 5px #00000033;
  border-radius: 15px;
}

.info-text {
  padding: 1rem 0 0 0;
  font-size: 1em !important;
  font-weight: 500;
  color: #000000cc;
  display: flex;
  text-align: justify !important;
}

.info-text-bottom {
  font-size: 0.8rem !important;
}

.show-more-btn {
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  color: #3483fa;
  text-decoration: underline;
  margin-top: 20px;
}

.card-reward-title {
  width: 90%;
  padding: 2rem 0 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #000000aa;
}

.card-reward-sub-title {
  width: 80%;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
}

.card-reward-label {
  padding: 1rem 0 1rem 0;
  font-size: 0.8rem;
}

@media (min-width: 720px) {
  .card-reward {
    width: 40%;
  }

  .info-text-bottom {
    font-size: 1rem !important;
  }
}

@media (min-width: 1366px) {
  .card-reward {
    width: 30%;
  }
}

@media (min-width: 1500px) {
  .card-reward {
    width: 23%;
  }
}
</style>
