<template>
  <div class="col-12 col-md-4 q-gutter-md q-mb-md" v-if="showCard()">
    <q-card class="card-points card-points-compacted" ref="cardPoints">
      <q-card-section
        class="card-points-header card-points-header-compacted"
        ref="cardPointsHeader"
        :style="`background-color: ${backgroundColor}; color: ${color};`"
      >
        <div class="row" @click="expandsCard()" style="cursor: pointer">
          <div
            class="col-2 column justify-center items-center"
            v-if="clusterPartnerId === clusterId"
          >
            <q-avatar style="width: 45px" class="q-mr-md">
              <q-img :src="partner.logo_foto" alt="User" />
            </q-avatar>
          </div>
          <div
            :class="
              `${
                clusterPartnerId === clusterId
                  ? 'col-9'
                  : 'col-11 q-pl-md q-my-sm'
              } column justify-around items-start`
            "
          >
            <span
              class="
               card-points-header-title 
              "
              :class="$q.platform.is.mobile ? 'q-ml-sm' : 'q-ml-xs'"
              style="font-size: 11pt;"
              >{{ level }}</span
            >
            <span
              class="card-points-header-subtitle text-weight-bold "
              :class="$q.platform.is.mobile ? 'q-ml-sm' : 'q-ml-xs'"
              v-if="clusterPartnerId === clusterId"
              >{{ $t("global.u_are_here") }}</span
            >
          </div>
          <div class="col-1 column justify-center items-center">
            <img
              :src="`${getArrowIcon()}`"
              :style="
                `transform: ${
                  isExpanded === true ? 'rotate(180deg)' : 'rotate(0)'
                }`
              "
            />
          </div>
        </div>
      </q-card-section>

      <q-card-section class="q-mt-sm" ref="cardPointsBody" v-if="isExpanded">
        <div class="row">
          <div class="col-6 text-weight-bold">
            <p class="card-text">
              {{ $t("my_points.faq.percent") }}
            </p>
          </div>
          <div class="col-6 text-right">
            <p class="card-text">{{ percent }}</p>
          </div>
        </div>
        <div class="row" v-if="initial != null">
          <div class="col-6 text-weight-bold">
            <p class="card-text">{{ $t("my_points.faq.initial_value") }}</p>
          </div>
          <div class="col-6 text-right">
            <p class="card-text">{{ toMoneyLabel(initial) }}</p>
          </div>
        </div>
        <div class="row" v-if="initial != null">
          <div class="col-6 text-weight-bold">
            <p class="card-text">{{ $t("my_points.faq.final_value") }}</p>
          </div>
          <div class="col-6 text-right">
            <p class="card-text">{{ toMoneyLabel(final) }}</p>
          </div>
        </div>
        <div v-if="carousel_images">
          <q-carousel
            animated
            v-model="slide"
            infinite
            :arrows="JSON.parse(carousel_images).length"
            :autoplay="true"
            height="280px"
          >
            <q-carousel-slide
              v-for="(image, i) in JSON.parse(carousel_images)"
              :key="i"
              :name="i"
              :img-src="image.image_url"
            />
          </q-carousel>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { ref, onMounted } from "vue";

export default {
  name: "CardPoints",

  props: {
    backgroundColor: {
      type: String,
      default: "#000",
    },
    color: {
      type: String,
      default: "#fff",
    },
    level: {
      type: String,
      default: "Default",
    },
    percent: {
      type: String,
      default: "8%",
    },
    initial: {
      type: String,
      default: "R$ 0,00",
    },
    final: {
      type: String,
      default: "R$ 50.000,00",
    },
    clusterId: {
      required: true,
    },
    clusterPartnerId: { required: true, default: null },
    partner: { required: true, type: Object },
    carousel_images: { type: Object },
  },

  setup(props) {
    const isExpanded = ref(false),
      cardPoints = ref(null),
      cardPointsBody = ref(null),
      cardPointsHeader = ref(null);

    const expandsCard = () => {
        isExpanded.value = !isExpanded.value;
      },
      showCard = () => {
        if (props.clusterPartnerId == null) {
          return props.initial != null && props.final != null ? true : false;
        }
        return true;
      };

    onMounted(() => {
      setTimeout(() => {
        if (props.clusterPartnerId == props.clusterId) expandsCard();
      }, 800);
    });

    return {
      showCard,
      isExpanded,
      cardPoints,
      expandsCard,
      toMoneyLabel,
      cardPointsBody,
      slide: ref(0),
      cardPointsHeader,
      getArrowIcon: () => {
        return props.backgroundColor == "#000000" ||
          props.backgroundColor == "#000000"
          ? "/awards-icons/down-arrow-icon.svg"
          : "/awards-icons/down-arrow-icon-black.svg";
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.card-points {
  border-radius: 15px;
}
.card-points-header {
  width: 100%;
  padding: 10px;
}
.card-points-header-compacted {
  border-radius: 15px !important;
}
.card-points-header-expanded {
  border-radius: 20px 20px 0 0 !important;
}
.card-points-header-title {
  font-weight: 600;
  text-transform: uppercase;
}
.card-points-header-subtitle {
  font-size: 0.8rem;
}
.card-points-body {
  width: 85%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin: auto;
}
.card-text {
  font-size: 10.3pt;
}
</style>
